const defaultSettings = {
    id: null,
    status: null,
    type: 'sendMessage',
    name: '',
    segmentations: {},
    message: {},
    settings: {
        intervalCap: 10
    },
    date_send: null
}

const STATUS_DRAFT = 0;
// const STATUS_NOT_STARTED= 1;
// const STATUS_IN_PROGRESS = 2;
// const STATUS_DONE = 3;
// const STATUS_CANCEL = 4;
// const STATUS_ERROR = 5;
// const STATUS_PREPARING_LIST = 6;
//
// const DATA_STATUS_WAIT_SEND = 0;
// const DATA_STATUS_IN_PROGRESS = 1;
// const DATA_STATUS_DONE = 2;
// const DATA_STATUS_CANCEL = 3;
// const DATA_STATUS_ERROR = 3;
// const DATA_STATUS_LOAD_FOR_SEND = 5;

// const newBotSetting = JSON.parse(localStorage.getItem(nameVariableForLS));

export default {
    namespaced: true,
    state: { ...defaultSettings }, // newBotSetting ??
    actions: {
    },
    getters: {
        editMode: function(state) {
            if (state.status === null || state.status === STATUS_DRAFT) {
                return true
            }
            return false
        },
        caption: function(state) {
            return state.status.message?.caption
        }
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        __set(state, {name, val}) {
            state[name] = val
        },
        __setMessageOption(state, {name, val}) {
            if (state.message === null) {
                state.message = {}
            }
            state.message[name] = val
        },
        __setMessageButton(state, {name, val, editButton}) {
            if (name === 'web_app') {
                if (state.message.reply_markup.inline_keyboard[editButton[0]][editButton[1]].web_app === undefined) {
                    state.message.reply_markup.inline_keyboard[editButton[0]][editButton[1]].web_app = {url: val }
                } else {
                    state.message.reply_markup.inline_keyboard[editButton[0]][editButton[1]].web_app.url = val
                }
                return
            }
            state.message.reply_markup.inline_keyboard[editButton[0]][editButton[1]][name] = val
        },
        __setMessageCreateButton(state, {row, col, val}) {
            if (state.message.reply_markup === undefined) {
                state.message.reply_markup = {}
            }
            if (state.message.reply_markup.inline_keyboard === undefined) {
                state.message.reply_markup.inline_keyboard = []
            }
            if (row === null) {
                state.message.reply_markup.inline_keyboard.push([])
                row =  state.message.reply_markup.inline_keyboard.length - 1
            }
            if (col === null) {
                state.message.reply_markup.inline_keyboard[row].push([])
                col =  state.message.reply_markup.inline_keyboard[row].length - 1
            }
            state.message.reply_markup.inline_keyboard[row][col] = val
        },
        __setMessageDelButton(state, {row, col}) {
            state.message.reply_markup.inline_keyboard[row].splice(col)

            if (!state.message.reply_markup.inline_keyboard[row].length) {
                state.message.reply_markup.inline_keyboard.splice(row)
            }
        },
        __setSettings(state, {name, val}) {
            if (state.settings === null) {
                state.settings = {}
            }
            state.settings[name] = val
        },
        addSegment(state, {type, val}) {
            if (state.segmentations === null) {
                state.segmentations = {}
            }
            state.segmentations[type] = val
        },
        removeSegment(state, {type}) {
            delete state.segmentations[type]
        },
        removeSegmentCustom(state, {segment_id} ) {
            state.segmentations.custom.forEach((item, i) => {
                if (item.segment_id === segment_id) {
                    state.segmentations.custom.splice(i, 1)
                }
            })
        },
        resetData(state) {
            for (let i in state) {
                state[i] = defaultSettings[i] ?? null
            }
        },
        loadData(state, data) {
            if (data.segmentations === null) {
                data.segmentations = {}
            }
            if (Array.isArray(data.segmentations)) {
                data.segmentations = {}
            }
            if (data.message === null) {
                data.message = {}
            }
            if (Array.isArray(data.message)) {
                data.message = {}
            }

            for (let i in data) {
                state[i] = data[i]
            }
        }
    }

}

