// import { authHeader } from './auth-header';
import Api from "./PreApiHandler";

export default {
    login,
    logout,
    register,
    requestPasswordReset,
    passwordReset,
    activateUser,
    tgAuthUp,
    tgAuthIn,
    getCdnKey,
    setTimezone,
    setReportCurrency,
    setEmail,
    sendEmailCode,
    setPassword,
    setCurrency,
    setLang,
    getKeyForTg
    // getAll,
};

async function login(email, password, rememberMe) {
    const request = {
        method: 'post',
        path: 'user/sign-in/login',
        body: {
            identity: email,
            password: password,
            rememberMe: rememberMe
        }
    }

    return Api.sendRequest(request)
}

async function logout() {
    const request = {
        method: 'post',
        path: 'user/sign-in/logout',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: {}
    }

    return Api.sendRequest(request)
}

function register(body) {
    const request = {
        method: 'post',
        path: 'user/sign-in/signup',
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        // },
        body: body
    }
    return Api.sendRequest(request)
}

function requestPasswordReset(email) {
    const request = {
        method: 'post',
        path: 'user/sign-in/request-password-reset',
        body: {
            email: email
        }
    }
    return Api.sendRequest(request)
}

function passwordReset(body) {
    const request = {
        method: 'post',
        path: 'user/sign-in/reset-password',
        body
    }
    return Api.sendRequest(request)
}

function activateUser(code) {
    const request = {
        method: 'get',
        path: 'user/sign-in/activation',
        params: {code}
    }
    return Api.sendRequest(request)
}

function tgAuthUp(body, params) {
    const request = {
        method: 'post',
        path: 'user/sign-in/tg-auth-up',
        body,
        params

    }
    return Api.sendRequest(request)
}
function tgAuthIn(body, params = {}) {
    const request = {
        method: 'post',
        path: 'user/sign-in/tg-auth-in',
        body,
        params

    }
    return Api.sendRequest(request)
}

function getKeyForTg(body, params = {}) {
    const request = {
        method: 'get',
        path: 'user/profile/get-auth-key',
        body,
        params

    }
    return Api.sendRequest(request)
}

function getCdnKey(params) {
    const request = {
        method: 'get',
        path: 'user/default/get-cdn-key',
        params: params
    }
    return Api.sendRequest(request)
}

async function setTimezone(params) {
    const request = {
        method: 'post',
        path: 'user/profile/set-time-zone',
        params: params
    }

    return Api.sendRequest(request)
}
async function setReportCurrency(params) {
    const request = {
        method: 'post',
        path: 'user/profile/set-report-currency',
        params: params
    }

    return Api.sendRequest(request)
}



async function setEmail(params) {
    const request = {
        method: 'post',
        path: 'user/profile/set-email',
        params: params
    }

    return Api.sendRequest(request)
}

async function sendEmailCode(params) {
    const request = {
        method: 'post',
        path: 'user/profile/email-code',
        params: params
    }

    return Api.sendRequest(request)
}

async function setPassword(body) {
    const request = {
        method: 'post',
        path: 'user/profile/set-password',
        body
    }

    return Api.sendRequest(request)
}

async function setCurrency(params) {
    const request = {
        method: 'post',
        path: 'user/profile/set-currency',
        params: params
    }

    return Api.sendRequest(request)
}

async function setLang(params) {
    const request = {
        method: 'post',
        path: 'user/profile/set-lang',
        params: params
    }

    return Api.sendRequest(request)
}
